import React from 'react'
import '../styles/Form.scss'

const Form = props => (
  <form className="petition-module" onSubmit={e => props.onSubmit(e)}>
    <label>
      First Name*
      <input
        type="text"
        name="first_name"
        value={props.formData.first_name}
        onChange={props.handleFormInputChange}
        required
      />
    </label>
    <label>
      Last Name
      <input
        type="text"
        name="last_name"
        value={props.formData.last_name}
        onChange={props.handleFormInputChange}
      />
    </label>
    <label>
      Email Address*
      <input
        type="email"
        name="email"
        value={props.formData.email}
        onChange={props.handleFormInputChange}
        onBlur={props.handleBlur}
        required
        className={props.emailValid ? '' : 'validation-error'}
      />
      {!props.emailValid && <span className="validation-error-cross">❌</span>}
    </label>
    <label>
      Mobile Number
      <input
        type="text"
        name="mobile"
        onChange={props.handleFormInputChange}
        value={props.formData.mobile}
      />
    </label>
    <label>
      Postcode*
      <input
        type="text"
        name="postcode_number"
        className="postcode-input"
        value={props.formData.postcode_number}
        onChange={props.handleFormInputChange}
        required
      />
    </label>
    <button type="submit" className="btn">
      VOTE NOW
    </button>
    <p style={{ color: 'white', fontSize: '13px', opacity: '0.7', marginTop: '-8px' }}>
      In taking action, I agree to GetUp's privacy policy{' '}
    </p>
  </form>
)

export default Form
