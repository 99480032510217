const data = [
  {
    id: 1,
    electorate: 'Dickson,QLD',
    name: 'Peter Dutton',
    img: 'peter_dutton',
    swing: '1.7%',
    points: [
      'Led leadership coup against Malcolm Turnbull',
      'Minister responsible for Manus and Nauru',
      'Wants to abandon the Paris Agreement',
      'Oversaw huge cuts to local hospitals as Health Minister',
      'Tried to introduce the GP co-payment as Health Minister',
    ],
    isActive: false,
  },
  {
    id: 2,
    electorate: 'Warringah,NSW',
    name: 'Tony Abbott',
    img: 'tony_abbott',
    swing: '11.6%',
    points: [
      'Destructive campaign to re-install himself as PM ',
      'Said climate change is "probably doing good" and wants to make it illegal to challenge polluters like Adani',
      'Wants to keep kids locked up in offshore detention',
      'Says police should enforce Indigenous kids attending school',
      'Key figure in No Campaign ',
    ],
    isActive: false,
  },
  {
    id: 3,
    electorate: 'Menzies,VIC',
    name: 'Kevin Andrews',
    img: 'kevin_andrews',
    swing: '10.6%',
    points: [
      'Tony Abbott’s most loyal supporter',
      'Founder of the Monash Forum to advance coal fired power stations',
      'Architect of WorkChoices that sought to strip away workers’ rights',
      'Overturned Australia’s first assisted dying laws',
      'Did not vote for Marriage Equality and called for gay conversion therapy',
    ],
    isActive: false,
  },
  {
    id: 4,
    electorate: 'Pearce,WA',
    name: 'Christian Porter',
    img: 'christian_porter',
    swing: '3.6%',
    points: [
      'Minister responsible for Robodebt scandal',
      'Authorised the prosecution of Witness K for whistle-blowing illegal ASIS actions',
      'Introduced laws to criminalise peaceful protest and laws aimed at gagging Civil Society',
      'Instructed Government members to vote for Neo-Nazi "OK to be White" motion',
      'Touted as a future PM',
    ],
    isActive: false,
  },
  {
    id: 5,
    electorate: 'Boothby,SA',
    name: 'Nicolle Flint',
    img: 'nicolle_flint',
    swing: '3.5%',
    points: [
      'Key backer of Dutton in leadership spill',
      "Called Tony Abbott “the thinking person's prime minister”",
      'Called accountability for bank CEOs “dangerous bank-bashing”',
      'Says ABC should give climate deniers as much air time as climate scientists',
    ],
    isActive: false,
  },
  {
    id: 6,
    electorate: 'Flinders,VIC',
    name: 'Greg Hunt',
    img: 'greg_hunt',
    swing: '7.8%',
    points: [
      'Dutton numbers man in leadership coup',
      'Purged any mention of the Reef from a UN climate report   ',
      'Knew of lead contamination in drinking water but did nothing to publicise it',
      'Overseen parlous rollout of My Health Record and misled public about rising cost of private health insurance',
      'Went on a misogynistic rant and intimidated Mayor',
    ],
    isActive: false,
  },
  {
    id: 7,
    electorate: 'Kooyong,VIC',
    name: 'Josh Frydenberg',
    img: 'josh_frydenberg',
    swing: '13.3%',
    points: [
      'Backed cuts to penalty rates ',
      'Called GetUp members "extreme" for wanting a stronger climate policy',
      'Ignored state calls to lift emissions reduction targets',
      'Rejected the Uluru statement, saying “every Australian already has equal rights"',
    ],
    isActive: false,
  },
  {
    id: 8,
    electorate: 'New England,NSW',
    name: 'Barnaby Joyce',
    img: 'barnaby_joyce',
    swing: '23.6%',
    points: [
      'Key backer of the Adani mega mine and member of the Monash Forum for coal-fired power stations',
      "Opposed Marriage Equality as 'against family values' while schtooping his Press Secretary",
      'Cut protections allowing animals to cook to death on live export ships',
      "Accused of tilting Murray-Darling Authority towards irrigators' interests",
    ],
    isActive: false,
  },
  {
    id: 9,
    electorate: 'Aston,VIC',
    name: 'Alan Tudge',
    img: 'alan_tudge',
    swing: '8.6%',
    points: [
      'Spearheaded cuts to income support payments',
      'Called our social safety net “poison”',
      'Leaked sensitive private information of innocent Centrelink recipient to media during Robodebt scandal',
      'Architect of the university-level English language test for Australian citizenship',
    ],
    isActive: false,
  },
  {
    id: 10,
    electorate: 'Robertson,NSW',
    name: 'Lucy Wicks',
    img: 'lucy_wicks',
    swing: '1.1%',
    points: [
      'Supported the Medicare GP co-payment ',
      'Voted to cut penalty rates to low paid workers',
      'Voted 27 times to block a Banking Royal Commission',
    ],
    isActive: false,
  },
  {
    id: 11,
    electorate: 'Moncrieff,QLD',
    name: 'Steve Ciobo',
    img: 'steve_ciobo',
    swing: '14.6%',
    points: [
      'Dutton numbers man in leadership coup',
      'Overturned a ban on government money financing coal mines',
      'Objected to refugees from Manus and Nauru being resettled in New Zealand ',
      'Major backer of the TPP ',
    ],
    isActive: false,
  },
  {
    id: 12,
    electorate: 'Cook,NSW',
    name: 'Scott Morrison',
    img: 'scott_morrison',
    swing: '15.4%',
    points: [
      'Architect of Manus, Nauru and boat turnbacks',
      'Cut $84m from our ABC',
      'Berated school strikers and told kids to “be less activist”',
      'Tried to subject people receiving income support to humiliating tests',
      'Did not vote for Marriage Equality',
    ],
    isActive: false,
  },
  {
    id: 13,
    electorate: 'Canning,WA',
    name: 'Andrew Hastie',
    img: 'andrew_hastie',
    swing: '6.8%',
    points: [
      "Tony Abbott's protege",
      'Strong backer of laws to criminalise peaceful protest ',
      'Did not vote for Marriage Equality',
      "Believes women should not be allowed to fight on frontline, and said “close combat is best preserved when it's exclusively male”",
    ],
    isActive: false,
  },
  {
    id: 14,
    electorate: 'Dawson,QLD',
    name: 'George Christensen',
    img: 'george_christensen',
    swing: '3.4%',
    points: [
      'Associates with extreme racist groups like Reclaim Australia',
      'Joined the Monash Forum to stop the “demonisation of coal”',
      'Posed with a gun, threatening “greenie punks”',
      'Did not vote for Marriage Equality',
    ],
    isActive: false,
  },
  {
    id: 15,
    electorate: 'Hume,NSW',
    name: 'Angus Taylor',
    img: 'angus_taylor',
    swing: '10.2%',
    points: [
      'Campaigns against wind farms and the Renewable Energy Target – as Energy Minister',
      'Singled out African-Australians for racist attacks during the Victorian election',
      'Wants to give spy agencies backdoors to encrypted information',
      'Touted as a future PM',
    ],
    isActive: false,
  },
  {
    id: 16,
    electorate: 'Grey,SA',
    name: 'Rowan Ramsey',
    img: 'rowan_ramsey',
    swing: '1.9%',
    points: [
      'Campaigning to build a nuclear waste dump in South Australia',
      'Backs oil drilling in the Great Australian Bight',
      'Supported cuts to penalty rates',
    ],
    isActive: false,
  },
  {
    id: 17,
    electorate: 'Hughes,NSW',
    name: 'Craig Kelly',
    img: 'craig_kelly',
    swing: '9.3%',
    points: [
      'Key Abbott ally',
      'Renowned climate skeptic and member of the Monash Forum',
      'Endorsed Donald Trump’s travel ban for people from Muslim-majority countries',
      'Subverted democracy to save his preselection when he lost support of branch members',
    ],
    isActive: false,
  },
  {
    id: 18,
    electorate: 'Deakin,VIC',
    name: 'Michael Sukkar',
    img: 'michael_sukkar',
    swing: '5.7%',
    points: [
      'Dutton numbers man in leadership coup against Malcolm Turnbull',
      'Called moderates in his party “termites” and pledged to crush them',
      'Said a "highly paid job" is the solution to housing affordability ',
      'Did not vote for Marriage Equality',
    ],
    isActive: false,
  },
];

export default data;
