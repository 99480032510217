import React, { Component } from 'react'
import closeButton from '../images/icons/close.svg'
import { withPrefix } from 'gatsby';

class SelectBarMp extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { mps, onClick, isFormVisible } = this.props;
    return (
      <ul>
        {mps.map(mp => (
            <li className="selected-electorate" key={mp.name}>
              <img src={withPrefix('/img/' + mp.img + '.png')} className="mp-image" />
              {mp.name}
              <span onClick={() => onClick(mp)}>
                {!isFormVisible && (
                  <img src={closeButton} className="sb-close-button" role="button" />
                )}
              </span>
            </li>
        ))}
      </ul>
    )
  }
}

export default SelectBarMp
