import React, { Component } from 'react'
import '../styles/selectbar.scss'
import closeButton from '../images/icons/close.svg'
import arrowDown from '../images/icons/arrow-down.svg'
import Form from './Form.jsx'
import SelectBarMp from './SelectBarMp.jsx'

import LoadingAnimation from '../components/LoadingAnimation.jsx'

class SelectBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      triggerValidationErrorNotification: false,
    }
  }
  togglePetition = () => {
    this.props.onNextClick()
  }

  handleRemoveSelectedMp = mp => {
    this.props.removeSelectedMp(mp)
  }

  handleSubmit = e => {
    e.preventDefault()
    if (!this.props.emailValid) {
      this.setState({ triggerValidationErrorNotification: true })
    } else {
      this.props.onFormSubmit(e)
    }
  }

  componentDidUpdate() {
    if (this.state.triggerValidationErrorNotification) {
      this.turnOffValidationErrorNotification = setTimeout(() => {
        this.setState({ triggerValidationErrorNotification: false })
      }, 2000)
    }
  }

  componentWillUnmount() {
    clearTimeout(this.turnOffValidationErrorNotification)
  }

  renderErrorNotification() {
    return <div className="notification">Please check if you've correctly entered the fields</div>
  }

  render() {
    const {
      mps,
      isFormVisible,
      formData,
      handleFormInputChange,
      onFormSubmit,
      handleBlur,
      emailValid,
    } = this.props
    return (
      <div
        className={
          'selectbar-container' +
          (mps.length === 0 ? ' hide-bar' : '') +
          (isFormVisible ? ' expanded' : '')
        }
      >
        <div style={{ display: 'flex', width: '100%' }}>
          <p className="selectbar-blurb" style={{ fontSize: '18px' }}>
            Select up to three then hit next
          </p>
          {isFormVisible && (
            <img
              src={arrowDown}
              role="button"
              className="arrowDown-button"
              onClick={e => this.togglePetition(e)}
            />
          )}
        </div>
        <SelectBarMp
          mps={mps}
          onClick={this.handleRemoveSelectedMp}
          isFormVisible={isFormVisible}
        />
        {this.props.isWaitingForResponse ? (
          <LoadingAnimation />
        ) : (
          isFormVisible && (
            <Form
              onSubmit={this.handleSubmit}
              formData={formData}
              handleFormInputChange={handleFormInputChange}
              handleBlur={handleBlur}
              emailValid={emailValid}
            />
          )
        )}
        <div
          role="button"
          className={'btn' + (isFormVisible ? ' hide' : '')}
          onClick={() => this.togglePetition()}
        >
          NEXT
        </div>
        {this.props.triggerLimitNotification && (
          <div className="notification">Select only up to 3</div>
        )}
        {this.state.triggerValidationErrorNotification && this.renderErrorNotification()}
      </div>
    )
  }
}

export default SelectBar
