import React from 'react'

const Header = ({ header }) => (
  <div className="row">
    <div className="small-12 columns">
      <h1 className="heading">{header.title}</h1>
      <p className="sub-heading">
        <b style={{ color: '#ff671f' }}>Voting is now closed! </b>
      </p>
      {header.subtitle.map(paragraph => (
        <p className="sub-heading" key={paragraph}>
          {paragraph}{' '}
        </p>
      ))}
      <p className="sub-heading bold">{header.subtitleBold}</p>
    </div>
  </div>
)

export default Header
