import React, { Component } from 'react'
import { withPrefix } from 'gatsby'
import '../styles/card.scss'

class Card extends Component {
  constructor(props) {
    super(props)
    this.state = {
      class: [
        // 'fade-out-right',
        // 'fade-out-left',
        // 'fade-out-up',
        // 'fade-out-down',
        // 'slide-out-right',
        // 'slide-out-left',
        // 'slide-out-up',
        // 'slide-out-down',
        // 'roll-out-right',
        // 'roll-out-left',
        // 'rotbo-out-right',
        // 'rotbo-out-left',
        // 'rotate-out-scale',
        // 'rotate-out-3d',
        // 'rotate-out-y',
        // 'rotate-out-x',
        // 'rotate-out',
        // 'turn-out-x',
        // 'turn-out-y',
        // 'back-out-right',
        // 'back-out-left',
        // 'back-out-up',
        // 'back-out-down',
        // 'clip-x-out',
        // 'clip-y-out',
        // 'clip-xy-out'
      ],
      isHovered: false,
    }
  }

  handleHover = status => {
    // extra check because sometimes it loses track. does the opposite
    if (status === 'enter') {
      this.setState({ isHovered: true })
    } else {
      this.setState({ isHovered: false })
    }
  }

  getRandomAnimation = () => {
    return this.state.class[Math.floor(Math.random() * this.state.class.length)]
  }

  toggleState = mp => {
    this.props.onClick(mp)
  }
  render() {
    const { mp } = this.props
    const hoverClass = this.state.isHovered ? this.getRandomAnimation() : 'fade-in'

    return (
      <div
        className="small-12 medium-6 large-4 column card-columns align-self-top"
        onMouseEnter={() => this.handleHover('enter')}
        onMouseLeave={() => this.handleHover()}
      >
        <div
          className={'card' + (mp.isActive ? ' active' : '')}
          onClick={() => this.toggleState(mp)}
        >
          <div className="card-img-container">
            <div className="mp-img">
              <img
                src={withPrefix('/img/' + mp.img + '.png')}
                // className={!mp.isActive ? 'animation ' + hoverClass : ''}
              />
              {/* <img src={withPrefix('/img/dutton.png')} /> */}
            </div>
            <p className="electorate-name">{mp.electorate}</p>
          </div>
          <div className="card-details-container">
            <p className="mp-name">{mp.name}</p>
            <p className="mp-swing"> MARGIN: {mp.swing}</p>
            <ul>
              {mp.points.map(nestedItem => (
                <li key={nestedItem}>{nestedItem}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

export default Card
